import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Hr, Button, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Tattoo Nexus
			</title>
			<meta name={"description"} content={"Від мінімалістичних мотивів до вишуканих композицій – ми пропонуємо повний спектр стилів, щоб створити татуювання вашої мрії."} />
			<meta property={"og:title"} content={"Tattoo Nexus"} />
			<meta property={"og:description"} content={"Від мінімалістичних мотивів до вишуканих композицій – ми пропонуємо повний спектр стилів, щоб створити татуювання вашої мрії."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b9c1efe778710018d2877e/images/2-4.jpg?v=2024-08-12T11:23:52.273Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="link1">
				ПОСЛУГИ
			</Override>
			<Override slot="text">
				Tattoo Nexus
			</Override>
		</Components.Header>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					Ознайомтесь з унікальним підходом до татуювань у Tattoo Nexus.
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					Дізнайтеся більше про нашу досвідчену команду, індивідуальні підходи до створення татуювань та нашу відданість майстерності.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/66ed22759335410018c66d65/images/download.jpeg?v=2024-09-20T12:06:27.842Z"
					width="100%"
					max-height="100%"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					srcSet="https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/download.jpeg?v=2024-09-20T12%3A06%3A27.842Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/download.jpeg?v=2024-09-20T12%3A06%3A27.842Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/download.jpeg?v=2024-09-20T12%3A06%3A27.842Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/download.jpeg?v=2024-09-20T12%3A06%3A27.842Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/download.jpeg?v=2024-09-20T12%3A06%3A27.842Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/download.jpeg?v=2024-09-20T12%3A06%3A27.842Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/download.jpeg?v=2024-09-20T12%3A06%3A27.842Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" margin="0px 0px 0px 0px" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" margin="0px auto 0px auto" />
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
			>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Наша історія: Від пристрасть до майстерності
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Tattoo Nexus створена з метою втілення мистецтва в реальність. Наші засновники, маючи багаторічний досвід, прагнули створити студію, де кожен малюнок на тілі стає витвором мистецтва. Сьогодні ми відомі своєю відданістю якості, привертаючи людей, які цінують майстерність і унікальний підхід. У Tattoo Nexus кожне татуювання є виразом вашої особистості, і ми прагнемо допомогти вам реалізувати вашу ідею найкращим чином.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 0px 0px" />
			</Box>
		</Section>
		<Section padding="90px 0 120px 0" background="#0B0008" quarkly-title="Team-15">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				grid-gap="46px 24px"
				md-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-rows="auto"
				sm-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 50px 0px 0px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					sm-padding="0px 0 0px 0px"
					sm-margin="0px 0px 15px 0px"
				>
					<Text margin="0px 0px 25px 0px" color="--orange" font="normal 300 20px/1.2 --fontFamily-mono">
						Наші Послуги
					</Text>
					<Text margin="0px 0px 30px 0px" color="--light" font="normal 900 48px/1.2 --fontFamily-sans">
						Втілення вашого бачення в життя
					</Text>
					<Text margin="0px 0px 40px 0px" color="--lightD2" font="300 18px/140% --fontFamily-sansHelvetica">
						В Tattoo Nexus ми пропонуємо послуги, що відповідають найвищим стандартам якості, включаючи:
						<br />
					</Text>
					<Button background="--color-orange" padding="15px 44px 15px 44px" font="normal 400 14px/1.5 --fontFamily-sansHelvetica">
						ДІЗНАТИСЯ БІЛЬШЕ &gt;&gt;
					</Button>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66ed22759335410018c66d65/images/Tattoo-artist-in-Alabama.jpg?v=2024-09-20T12:06:27.853Z"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
						srcSet="https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/Tattoo-artist-in-Alabama.jpg?v=2024-09-20T12%3A06%3A27.853Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/Tattoo-artist-in-Alabama.jpg?v=2024-09-20T12%3A06%3A27.853Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/Tattoo-artist-in-Alabama.jpg?v=2024-09-20T12%3A06%3A27.853Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/Tattoo-artist-in-Alabama.jpg?v=2024-09-20T12%3A06%3A27.853Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/Tattoo-artist-in-Alabama.jpg?v=2024-09-20T12%3A06%3A27.853Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/Tattoo-artist-in-Alabama.jpg?v=2024-09-20T12%3A06%3A27.853Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/Tattoo-artist-in-Alabama.jpg?v=2024-09-20T12%3A06%3A27.853Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
						Індивідуальний дизайн: Разом з нашими художниками створіть дизайн, який виражає вашу унікальність.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66ed22759335410018c66d65/images/1184219605.webp?v=2024-09-20T12:06:27.939Z"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
						srcSet="https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/1184219605.webp?v=2024-09-20T12%3A06%3A27.939Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/1184219605.webp?v=2024-09-20T12%3A06%3A27.939Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/1184219605.webp?v=2024-09-20T12%3A06%3A27.939Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/1184219605.webp?v=2024-09-20T12%3A06%3A27.939Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/1184219605.webp?v=2024-09-20T12%3A06%3A27.939Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/1184219605.webp?v=2024-09-20T12%3A06%3A27.939Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/1184219605.webp?v=2024-09-20T12%3A06%3A27.939Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
						Оновлення старих татуювань: Оживіть свої старі татуювання завдяки майстерним перекриттям.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66ed22759335410018c66d65/images/hands-drawing-with-tattoo-pen-arm_23-2147834099.jpg?v=2024-09-20T12:06:27.851Z"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
						srcSet="https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/hands-drawing-with-tattoo-pen-arm_23-2147834099.jpg?v=2024-09-20T12%3A06%3A27.851Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/hands-drawing-with-tattoo-pen-arm_23-2147834099.jpg?v=2024-09-20T12%3A06%3A27.851Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/hands-drawing-with-tattoo-pen-arm_23-2147834099.jpg?v=2024-09-20T12%3A06%3A27.851Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/hands-drawing-with-tattoo-pen-arm_23-2147834099.jpg?v=2024-09-20T12%3A06%3A27.851Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/hands-drawing-with-tattoo-pen-arm_23-2147834099.jpg?v=2024-09-20T12%3A06%3A27.851Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/hands-drawing-with-tattoo-pen-arm_23-2147834099.jpg?v=2024-09-20T12%3A06%3A27.851Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/hands-drawing-with-tattoo-pen-arm_23-2147834099.jpg?v=2024-09-20T12%3A06%3A27.851Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
						Класичні чорно-білі татуювання: Елегантні і позачасові, наші роботи виконані з бездоганною точністю.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66ed22759335410018c66d65/images/11062b_99374728561640df828f343f2616bc23~mv2.webp?v=2024-09-20T12:06:27.835Z"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
						srcSet="https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/11062b_99374728561640df828f343f2616bc23~mv2.webp?v=2024-09-20T12%3A06%3A27.835Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/11062b_99374728561640df828f343f2616bc23~mv2.webp?v=2024-09-20T12%3A06%3A27.835Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/11062b_99374728561640df828f343f2616bc23~mv2.webp?v=2024-09-20T12%3A06%3A27.835Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/11062b_99374728561640df828f343f2616bc23~mv2.webp?v=2024-09-20T12%3A06%3A27.835Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/11062b_99374728561640df828f343f2616bc23~mv2.webp?v=2024-09-20T12%3A06%3A27.835Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/11062b_99374728561640df828f343f2616bc23~mv2.webp?v=2024-09-20T12%3A06%3A27.835Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/11062b_99374728561640df828f343f2616bc23~mv2.webp?v=2024-09-20T12%3A06%3A27.835Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
						Яскраві кольорові татуювання: Втілення кольорів, що залишаються яскравими довгі роки.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/66ed22759335410018c66d65/images/20220623_iDMEYNK9Lcv4gHv.webp?v=2024-09-20T12:06:28.004Z"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
						srcSet="https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/20220623_iDMEYNK9Lcv4gHv.webp?v=2024-09-20T12%3A06%3A28.004Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/20220623_iDMEYNK9Lcv4gHv.webp?v=2024-09-20T12%3A06%3A28.004Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/20220623_iDMEYNK9Lcv4gHv.webp?v=2024-09-20T12%3A06%3A28.004Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/20220623_iDMEYNK9Lcv4gHv.webp?v=2024-09-20T12%3A06%3A28.004Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/20220623_iDMEYNK9Lcv4gHv.webp?v=2024-09-20T12%3A06%3A28.004Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/20220623_iDMEYNK9Lcv4gHv.webp?v=2024-09-20T12%3A06%3A28.004Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66ed22759335410018c66d65/images/20220623_iDMEYNK9Lcv4gHv.webp?v=2024-09-20T12%3A06%3A28.004Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
						Догляд за татуюванням: Отримайте поради для догляду за новим татуюванням для збереження його яскравості.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="140px 0 140px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/66ed22759335410018c66d65/images/13-Best-Tattoo-Shops-and-Artists-in-Adelaide-Black-Diamond-Tattoo.jpeg?v=2024-09-20T12:06:27.881Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Дізнайтеся більше про нас
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Це лише короткий огляд того, що може запропонувати Tattoo Nexus. Ми запрошуємо вас відвідати нас і дізнатися більше про наші послуги, розроблені для задоволення ваших індивідуальних потреб у татуюваннях. Наш дружній персонал завжди готовий надати додаткову інформацію та допомогти вам вибрати найкращі варіанти для реалізації вашої візії.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Контакти
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<Components.Privacy />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});